<template>
  <div>
    <el-affiex>
      <nav-bar></nav-bar>
    </el-affiex>
    <tool-box></tool-box>
    <div class="container protocol my-1" v-if="type == 1">
      <h3>《{{siteInfo.info.sitename}}用户协议》</h3>
      <p>本协议为您与本系统/App/平台(下称本系统/App/平台)版权所有者之间所订立的契约，具有合同的法律效力，请您仔细阅读。</p>
      <p>一、本协议内容、生效、变更。本协议内容包括协议正文及所有本系统/App/平台 已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。如您对协议有任何疑问，应向本系统/App/平台 咨询。只要您使用本系统/App/平台 平台服务，则本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得本系统/App/平台对您问询的解答等理由，主张本协议无效，或要求撤销本协议。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用本系统/App/平台服务。本系统/App/平台 有权根据需要不定期地制订、修改本协议及/或各类规则，并在本系统/App/平台 平台公示，不再另行单独通知用户。变更后的协议和规则一经在 App 公布，立即生效。如您不同意相关变更，应当立即停止使用本系统/App/平台 平台服务。您继续使用本系统/App/平台 平台服务的，即表明您接受修订后的协议和规则。</p>
      <p>二、本系统/App/平台所刊载的所有资料及图表仅供参考使用。用户明确同意其使用本系统/App/平台 平台网络服务所存在的风险将完全由其自己承担；因其使用本系统/App/平台 平台网络服务而产生的一切后果也由其自己承担，本系统/App/平台 平台对用户不承担任何责任。</p>
      <p>三、本系统/App/平台 平台的用户在参加本系统/App/平台 平台举办的各种活动时，我们将在您的同意及确认下，通过注册表格等形式要求您提供一些个人资料，如：您的姓名、性别、年龄、出生日期、身份证号、家庭住址、教育程度、公司情况、所属行业等。我们在未经您同意的情况下，绝对不会将您的任何资料以任何方式泄露给任何第三方。</p>
      <p>四、当政府司法机关依照法定程序要求本系统/App/平台披露个人资料时，我们将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，本系统/App/平台均得免责。</p>
      <p>五、任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，本系统/App/平台 平台均得免责。</p>
      <p>六、由于与本系统/App/平台链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，本系统/App/平台均得免责。</p>
      <p>七、本系统/App/平台如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本网站控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本系统/App/平台不负任何责任。</p>
      <p>八、本系统/App/平台使用者因为违反本声明的规定而触犯中华人民共和国法律的，一切后果自己负责，本系统/App/平台不承担任何责任。</p>
      <p>九、凡以任何方式登陆本系统/App/平台 平台或直接、间接使用本系统/App/平台资料者，视为自愿接受本系统/App/平台 平台声明的约束。</p>
      <p>十、本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。</p>
      <p>十一、本系统/App/平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
      <p>十二、本系统/App/平台不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本系统/App/平台实际控制的任何网页或平台上的内容，本系统/App/平台不承担任何责任。</p>
      <p>十三、对于因不可抗力或本系统/App/平台不能控制的原因造成的网络服务中断或其它缺陷，本系统/App/平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
      <p>十四、本系统/App/平台所有页面的版式、图片版权均为本系统/App/平台所有，未经授权，不得用于除本系统/App/平台之外的任何站点。</p>
      <p>十五、本系统/App/平台之声明以及其修改权、更新权及最终解释权均属本系统/App/平台 平台所有。</p>
    </div>
    <div class="container protocol" v-if="type == 2">
      <h3>《{{siteInfo.info.sitename}}隐私协议》</h3>
      <p>
        {{siteInfo.info.company_name}}（以下简称“我们”）尊重并保护所有使用{{siteInfo.info.sitename}}平台（网站系统、App）服务用户的个人隐私权。为向您提供更准确、更详细的个性化服务，我们会按照此隐私政策的规定使用和披露您的个人信息，并审慎使用、保护您所录入的信息。
      我们将持续更新此隐私政策，一旦选择同意本软件服务使用协议，即代表您已同意此隐私政策全部内容。本隐私政策属于时刻未来服务使用协议不可分割的一部分。请在使用我们的产品（或服务）前，仔细阅读并了解《时刻未来隐私政策》。
      </p>
      <h4>一、信息收集及使用</h4>
      <p>
        <b>1.收集</b>
      </p>
      <p>
      （1）当您使用时刻未来产品服务时，我们将按照您设备的隐私设置自动接收并记录您设备上的信息，包括但不限于您的设备语言、日期时间等相关数据。
      </p>
      <p>
        （2）当您使用时刻未来产品服务时，我们会收集您在产品内部的使用痕迹及数据，帮助实现应用更新、缓存清理等功能。若您使用微信关联登录时刻未来，我们会收集您微信的头像、昵称，用于保存您的登录信息，使您在使用不同设备登录时能够同步您的数据。
      </p>
      <p>
        （3）为识别您的设备ID，保障您正常使用我们的服务，改善及优化您的服务体验并保障您的账号安全，我们会收集您的设备标识、硬件参数、系统版本、网络状态等信息。
      </p>
      <p>
        <b>2.使用</b>
      </p>
      <p>
        在获得您的数据后，本软件会将其上传至服务器，以生成您学习、购买、咨询等方面的相关报告，以便您更好地享受软件服务。
      </p>
      <h4>二、设备权限调用</h4>
      <p>
      {{siteInfo.info.sitename}}产品在向您提供服务的过程中，会调用您设备的写入外置存储器、读取外置存储器、拍摄等权限，以实现问题反馈、题目视频纠错、离线下载等功能。您可以在您设备的设置功能中选择关闭部分或全部权限，从而拒绝时刻未来客户端取得对应权限，但该选择可能导致您无法使用我们提供相关功能。
      </p>
      <h4>三、信息披露</h4>
      <p>
      1.一般情况下，在未征得您事先许可的情况下，本软件不会将任何个人信息提供或披露给第三方。
      </p>
      <p>
      2.根据相关法律法规及国家标准，如遇以下情形，我们可能会在不征求您意见的情况下，收集、使用您的相关个人信息：
      </p>
      <p>（1）与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；</p>
      <p>（2）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>（3）出于维护您或您个人的生命、财声誉等重大合法权益但又很难得到本人同意的；</p>
      <p>（4）所收集的个人信息是您自行向社会公众公开的；</p>
      <p>（5）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>（6）根据您的要求签订和履行合同所需要的；</p>
      <p>（7）用于维护所提供的产品服务安全稳定运行所需要的（例如处置产品或服务故障）；</p>
      <p>（8）为开展合法的新闻报道所需要的；</p>
      <p>（9）出于公共利益开展统计或学术研究所需要的，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去表示化处理的；</p>
      <p>（10）法律法规规定的其他情形。</p>
      <h4>四、信息存储和交换</h4>
      <p>
      按照以上规定所收集的有关您的信息和资料将保存在{{siteInfo.info.company_name}}及（或）关联机构的服务器上。
      </p>
      <h4>五、信息安全</h4>
      <p>
      在使用本软件网络服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式、邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络软件客服，以便我们采取相应措施。
      </p>
      <h4>六、用户（信息）注销</h4>
      <p>
      当您符合约定的账户注销条件并注销个人时刻未来账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但您在使用时刻未来服务期间提供或产生的信息，我们仍需按照监管要求的时间进行保存，且在该保存的时间内依法配合有权机关的查询。
      </p>
    </div>
    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/components/page/NavBar'
import WebFooter from '@/components/page/WebFooter'
import ToolBox from '@/components/toolbox'
import { getLocalStorage,setLocalStorage } from '@/utils/auth'
import {getSiteInfo} from '@/api/user'

export default {
  data() {
    return {
      type:1,//1:用户协议；2:隐私协议
      siteInfo:''
    }
  },
  components: {
    NavBar,
    WebFooter,
    ToolBox,
  },
  props:{

  },
  created(){
    this.type = this.$route.params.type || 1
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
        this.siteInfo = getLocalStorage('siteInfo')
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  width:1200px;
  margin:20px auto;
}
.protocol{
  background-color:white;
  padding:20px;
  text-indent: 2em;
  h3{
    text-align: center;
    font-size:18px;
    font-weight: bold;
    line-height:40px;
  }
  h4{
    font-size:16px;
    font-weight: bold;
    line-height:40px;
  }
  p{
    font-size:16px;
    line-height:30px;
  }
}
</style>